import React from 'react'
import styled from 'styled-components'
// import HeroVideo from '../../Videos/Herovideo.webm'
import colors from '../../colors'
import { useState, useEffect } from 'react'
// import Nahrada from '../../Img/g18.JPG'
import { keyframes } from 'styled-components'
import HeroVid from './HeroVid'




const HeroCont = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: flex-start;
  position: relative;
  z-index: 2;
  
`

const Hello = styled.h1`
  padding: 6px;
  text-align: start;
  font-family: 'Playfair Display', serif;
  color: rgb(204, 214, 246);
  z-index: 2;
  top: 500px;
  font-size: 35px;
  text-shadow: rgba(0, 0, 0, 0.94) 5px 5px 4px;
  letter-spacing: 1px;

  @media (max-width: 850px){
    font-size: 21px;
    text-align: center;

  }
  
 
  
`



const BlurDiv = styled.div`
  width: 100%;
  height: 100vh;
  background: #080808;
  z-index: 2;
  opacity: 0.6;

`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const DescCont = styled.div`
  display: flex;
  position: absolute;
  z-index: 2;
  flex-direction: column;
  top: 285.4px;
  height: 320px;
  justify-content: space-around;
  left: 15%;
  animation: ${fadeIn} 3s ease-in-out forwards;
  
  
  @media (max-width: 850px ) {
    width: 70%;
    align-items: center;
  }


  @media (max-width: 640){
    top: 350.4px;
  }

  @media (max-height: 800){
    top: 350.4px;
  }
  `

  // const HeroImg = styled.div`
  //   background-image: url(${Nahrada});
  //   width: 100%;
  //   height: 965.5px;
  //   position: absolute;
  //   z-index: 1;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: cover;


  // `






const Span = styled.span`
  color: ${colors.barber};
`

const StoryCont = styled.button`
  font-family: 'Playfair Display', serif;
  background: none;
  width: 115px;
  height: 55px;
  z-index: 2;
  cursor: pointer;
  position: relative;
  color: ${colors.white};
  font-weight: 900;
  font-size: 14px;
  border: 2px solid ${colors.barber};
  transition: background 1s ease, color 1s ease;
  

  &:hover {
    background: ${colors.barber};
    color: ${colors.Black};
  }



`

const Hero = () => {
  
  const [
    //scrollPosition//
  ,setscroolPosition] = useState(window.scrollY)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  
  

  const handleScrool = ()=> {
    
    setscroolPosition(window.scrollY);
  }

  const handleResize = ()=> {
    setScreenWidth(window.innerWidth)
  }

  useEffect(()=>{

    window.addEventListener("resize", handleResize)

      window.addEventListener('scroll', handleScrool) 

      return ()=> {
        window.removeEventListener("resize", handleResize)
        window.removeEventListener('scroll', handleScrool)
      }

  }, [])

  

  const handleClick = ()=> {
    window.scrollTo({
      top: screenWidth <= 1323 ? 1300 : 995,
      left: 0,
      behavior: 'smooth'
    })
  }




  return (
    <HeroCont className='HeroCont'>
      <BlurDiv className='BlurDiv'></BlurDiv>
      <DescCont>
    
        <Hello>Ochutnejte atmosféru našeho barbershopu,<br/> <Span> ukrytého přímo v centru města Litovel.</Span></Hello>
        
            <StoryCont onClick={handleClick}>Začít</StoryCont>
      </DescCont>

      {/* <HeroImg className='hero'></HeroImg> */}
      <HeroVid className='video' autoPlay muted loop />
      
    </HeroCont>
  )
}

export default Hero;