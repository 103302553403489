import styled from 'styled-components';
import Logo from '../../SVG/Logo';
import colors from '../../colors';
import Rezervation from '../MailerForm/Rezervation';
import NavBarb from '../../Img/barber.png'
import { useEffect, useState, useContext } from 'react';
import { useSpring, animated, config } from 'react-spring'
import { 
  HeroRefContext, 
  AboutRefContext, 
  ServicesRefContext, 
  GalleryRefContext 
} from '../../props/Ref';



const NavCont = styled.nav`
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 4;
  width: 100%;
  top: ${({ isScrollingUp }) => isScrollingUp ? '-103px' : '0'};
  transition: top 0.5s ease-out;
  
 
  
`
const Ul = styled.ul`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: none;
  border-left: none;
  border-right: none;
  backdrop-filter: blur(7px);
  background-color: rgb(13 13 13 / 45%);
  transition: background-color 0.5s ease-in;
  
}

`
// const LiCont = styled.div`
//   display: flex;
//   -webkit-box-pack: space-around;
//   justify-content: space-around;
//   width: 28.4%;
// `

const Li = styled.li`
  list-style-type: none;
  padding: 16px 34px;
  text-align:center;
  width: 200px;
  
  @media (max-width: 1400px) {
    display: none;
  }
`
const Links = styled.a`
  font-family: 'Playfair Display', serif;
  font-weight: 300;
  color: ${colors.white};
  padding: 11px;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.2s ease, border-bottom 1s ease;
  
  
  &:hover {
    color:${colors.barber};
      
  }

`



const Hero = styled.img`
  position: absolute;
  top: 28px;
  width: 47px;
  height: 44px;
  left: 20.8%;
  @media (max-width: 1860px) {
    left: 19.8%;
  }

  @media (max-width: 1800px) {
    left: 20.8%;
  }
  

`

const Barber = styled.img`
  position: absolute;
  top: 28px;
  width: 47px;
  height: 44px;
  left: 35.7%;
  @media (max-width: 1860px) {
    left: 34.7%;
  }
`

const Galery = styled.img`
  position: absolute;
  top: 28px;
  width: 47px;
  height: 44px;
  right: 44.5%;
  @media (max-width: 1860px) {
    right: 45.5%;
  }

  @media (max-width: 1700px) {
    right: 46.5%;
  }
`

const Services = styled.img`
  position: absolute;
  top: 28px;
  width: 47px;
  height: 44px;
  right: 27.9%;

  @media (max-width: 1860px) {
    right: 28.9%;
  }

  @media (max-width: 1700px) {
    right: 29.9%;
  }

  @media (max-width: 1512px) {
    right: 30.9%;
  }
`

const GT = styled(Logo)`
  padding: 20px;
  height: 180px;
  width: 272px;
  border-image: initial;
  border-left: none;
  border-top: none;
  border-bottom-right-radius: 224px;
  position: relative;
  top: 5px;
}
  @media (max-width: 1400px){
    width: 180px;
  }

  @media (max-width: 430px){
    width: 140px;
    height: 140px;
  }
`

const BarbCont = styled.div`
  width: 128px;
  
`
const Burger = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

 

 

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${colors.barber};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
   
    
  
   

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }

  @media (min-width: 1401px) {
    display: none;
  }

  
`


const HiddenMenu = styled.div`
  position: absolute;
  z-index: 3;
  height: 293px;
  width: 100%;
  background-color:  rgb(13 13 13 / 45%);
  top: 98.4px;
  backdrop-filter: blur(2px);
  border-bottom: 2px solid rgb(213 189 99);
  

  

`

const HiddenFlexNav = styled.div`
  display: flex;
  flex-direction: column;
  height: 293px;
  justify-content: space-around;
  align-items: center;
`

const HidenNav = styled.a`
  font-family: 'Playfair Display', serif;
  padding: 14px;
  width: 174px;
  text-align: center;
  color: ${colors.white};
  cursor: pointer;
  transition: color 0.5s ease, border 0.2s ease-in;
  border: 2px solid rgb(213 189 99);
  text-transform: uppercase;

  &:hover {
    color: rgb(213 189 99);
    border: 2px solid ${colors.white};
  };

;
`

const AnimatedHidenMenu = animated(HiddenMenu);







const Header = () => {

  const [hero, setHero] = useState(false);
  const [barber, setBarber] = useState(false);
  const [galery, setGalery] = useState(false);
  const [services, setServices] = useState(false);
  const [open, setOpen] = useState(false);


  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  
  const [scroolPosition, setScroolPosition] = useState(window.scrollY);

  const heroRef = useContext(HeroRefContext);
  const aboutRef = useContext(AboutRefContext);
  const servicesRef = useContext(ServicesRefContext);
  const galleryRef = useContext(GalleryRefContext);
  

  useEffect(() => {
    const onScroll = () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st <= 0) {
        setIsScrollingUp(false);
      } else {
        setIsScrollingUp(st > lastScrollTop);
      }
      setLastScrollTop(st <= 0 ? 0 : st);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    setOpen(isScrollingUp ? false : null);
  }, [isScrollingUp]);



  const handleScrol = ()=> {
    setScroolPosition(window.scrollY)
  }

    useEffect(()=>{
      window.addEventListener("scroll", handleScrol)
    

      return ()=> {
        window.removeEventListener("scroll", handleScrol)
      
      }

    },[])

   

  const burgerOpen = () => {
    setOpen(!open);
  }

  //////////////////////////////////////Hover Funkce NůžkyEffect
  const hoverHeroFunc = ()=> {
    setHero(true)
  }

  const leaveHeroFunc = ()=> {
    setHero(false)

  }

  const hoverBarberFunc = ()=> {
    setBarber(true)
  }

  const leaveBarberFunc = ()=> {
    setBarber(false)

  }

  const hoverGaleryFunc = ()=> {
    setGalery(true)
  }

  const leaveGaleryFunc = ()=> {
    setGalery(false)

  }

  const hoverServicesFunc = ()=> {
    setServices(true)
  }

  const leaveServicesFunc = ()=> {
    setServices(false)

  }
  ////////////////////////////////////////////////

  const SetHandelHero = () => {
    heroRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const setHandleAbout = () => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const setHandleServices = () => {
    servicesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const setHandleGalery = () => {
    galleryRef.current.scrollIntoView({ behavior: "smooth" });
  };







const animationProps = useSpring({
  from: { height: "0px", opacity: 0 },
  to: {
    height: open ? "320px" : "0px",
    opacity: open ? 1 : 0
  },
  config: config.wobbly,
});

  

  

  
  return (
      <NavCont isScrollingUp={isScrollingUp}>

      { open && 
            <AnimatedHidenMenu className="hiddenmenu"
            style={animationProps}
            scroolPosition={scroolPosition}>

              <HiddenFlexNav>
                <HidenNav
                  onMouseEnter={hoverHeroFunc}
                  onMouseLeave={leaveHeroFunc} 
                  onClick={SetHandelHero}>
                Úvod</HidenNav>


                <HidenNav
                  onMouseEnter={hoverBarberFunc}
                  onMouseLeave={leaveBarberFunc}
                  onClick={setHandleAbout}
                >
                  
                O nás
                
                </HidenNav>
                <HidenNav
                  onMouseEnter={hoverServicesFunc}
                  onMouseLeave={leaveServicesFunc}
                  onClick={setHandleServices}
                >Služby
                
                </HidenNav>
                <HidenNav
                  onMouseEnter={hoverGaleryFunc}
                  onMouseLeave={leaveGaleryFunc}
                  onClick={setHandleGalery}
                >

                Galerie
                
                </HidenNav>
              </HiddenFlexNav>

            </AnimatedHidenMenu>
        }
      <Ul scroolPosition={scroolPosition} >   
      <GT></GT>
          <Li>
            <BarbCont>
           
          {hero && <Hero src={NavBarb}></Hero>}
            <Links
              onMouseEnter={hoverHeroFunc}
              onMouseLeave={leaveHeroFunc} 
              onClick={SetHandelHero}>
                Úvod
            </Links>
            </BarbCont>
          </Li>

        <Li>
        <BarbCont>
          {barber && <Barber src={NavBarb}></Barber> }
          
          <Links
           onMouseEnter={hoverBarberFunc}
           onMouseLeave={leaveBarberFunc}
           onClick={setHandleAbout}
           > 
            O nás
          </Links>
          </BarbCont>
        </Li>
        
        
        <Li>
        <BarbCont>
        {services && <Galery  src={NavBarb}></Galery>}
          <Links
          onMouseEnter={hoverServicesFunc}
          onMouseLeave={leaveServicesFunc}
          onClick={setHandleServices}
          >
              Služby
          </Links>
        </BarbCont>
        </Li>


        <Li>
          <BarbCont>
          {galery && <Services src={NavBarb}></Services> }
            <Links 
            onMouseEnter={hoverGaleryFunc}
            onMouseLeave={leaveGaleryFunc}
            onClick={setHandleGalery}
            >
              Galerie
            </Links>
          </BarbCont>
        </Li>

        <Burger open={open} onClick={burgerOpen}>
              <div />
              <div />
              <div />
            </Burger>
        <Rezervation></Rezervation>
      </Ul>
    </NavCont>
  )
}

export default Header
