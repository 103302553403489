import React, { useState, createContext, useContext } from 'react'
import { ServiceContext } from './ServicesId';

    const ReservationContext = createContext();

export const ReservationProvider = ({children})=> {
    const [isHiden, setIsHiden] = useState(true)

        const { setServiceId } = useContext(ServiceContext)

        const HandleClick = ()=> {
            setIsHiden((v)=>!v)
            setServiceId(null)
        }
        

    return (
        <ReservationContext.Provider value={{isHiden, HandleClick}}>
            {children}
        </ReservationContext.Provider>
    )

}
    export const useReservation = ()=> {
        return useContext(ReservationContext)
    };
