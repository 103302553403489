import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import colors from '../colors'
import Cookies from 'js-cookie';
import { dataCookiesText } from '../CookieBanner/dataCookiesText'

const FlexBanner = styled.div`
    top: 90%;
    height: 75px;
    width: 100%;
    position: fixed;
    z-index: 5;
    background-color: rgb(213 189 99 / 64%);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    backdrop-filter: blur(6px);

    @media(max-width: 1407px){
      height: 220px;
      top: 73%;
    }

    @media(max-height: 818px){
      top: 70%
    }

    @media(max-height: 770px){
      top: 71%
    }

    @media(max-height: 735px){
      top: 66%
    }
    @media(max-height: 647px){
      top: 60%
    }

    @media(max-height: 549px){
      top: 50%
    }


    
    
    

`

const TextBanner = styled.h3`
font-size: 15px;
line-height: 30px;
font-family: "Roboto", serif;
    margin: 0px 30px;
    font-weight: 900;
    text-align: center;
    

`

const FlexContDiv = styled.div`
  width: 351px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  
`

const CookiesButton = styled.button`
  font-family: 'Roboto Mono', serif;
  cursor: pointer;
  border: none;
  padding: 15px;
  width: 40%;

`

const CookieBanner = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  useEffect(() => {
    const checkCookieConsent = () => {
      setAcceptedCookies(Cookies.get('acceptedCookies') === 'true');
    };
    
    checkCookieConsent();
  }, []);

  const acceptCookies = () => {
    setAcceptedCookies(true);
    Cookies.set('acceptedCookies', 'true', { expires: 365 }); // Cookie expiruje po 1 roce
  }

  // Pokud byly cookies přijaty, nezobrazujeme banner.
  if (acceptedCookies) {
    return null;
  }

  return (
    <FlexBanner className='cookies banner'>
      <TextBanner>{dataCookiesText}</TextBanner>
      <FlexContDiv>
        <CookiesButton 
          onClick={acceptCookies}
          style={{
            backgroundColor: `${colors.Black}`,
            color: `${colors.white}`,
        }}>
          Rozumím
        </CookiesButton>
      </FlexContDiv>
    </FlexBanner>
  )
}

export default CookieBanner
