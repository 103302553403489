import React, { createContext, useState } from 'react';


// Vytvořte nový kontext
const ServiceContext = createContext();

// Vytvořte Provider
const ServiceProvider = ({ children }) => {
  const [serviceId, setServiceId] = useState(null);



  return (
    <ServiceContext.Provider value={{ serviceId, setServiceId }}>
      {children}
    </ServiceContext.Provider>
  );
};

export { ServiceContext, ServiceProvider };