import React, { useState } from 'react'
import BarberAlert from './BarberAlert'
import styled, { keyframes } from 'styled-components';
import { useWindowContext } from '../../props/ScrollWidth';

const Scale = keyframes`
    0% {
        transform: scale(1)
    }
    
    50% {
        transform: scale(1.2)
    }

    100% {
        transform: scale(1)
    }

`

const BannerCont = styled.div`
    display: ${(props) => (props.$isHiden ? 'none' : 'flex')};
    min-width: 720px;
    height: 100vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1;
    background-image: linear-gradient(to right bottom, rgb(42, 25, 9), rgb(213, 189, 99), rgb(129, 98, 53), rgb(172, 142, 75), rgb(213, 189, 99));
    align-items: center;
    justify-content: center;
    padding: 0px 50px;
    flex-direction: column;
    overflow-y: auto;


    @media(max-width: 980px) {
        width: 100%;
    }

    
`

const FlexDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 618px;
    align-items: center;

    @media(max-width: 629px) {
        height: 832px;
    }
`

const Alert = styled(BarberAlert)`
    animation: ${Scale} 1s ease-in-out infinite;
    margin-bottom: 40px;
  

    @media(max-width: 629px){
        width: 40px;
       
    }
    
`

const TextBanner = styled.p`
    font-family: 'Roboto';
    text-align: center;
`

const TextCont = styled.div`
    background-color: rgba(0, 0, 0, 0.73);
    padding: 50px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 477px;

    @media(max-width: 629px){
        width: 366px;
        height: 640px;
    }
    @media(max-width: 369px){
        width: 330px;
    }
`

const Span = styled.span`
    color: #ff3f3f;
    font-weight: 900;
    font-size: clamp(15px, 1.3vw, 17px);
`

const SpanWhite = styled.span`
    color: white;
    font-size: clamp(15px, 1.3vw, 17px);
`

const Button = styled.button`
    font-family: 'Roboto';
    cursor: pointer;
    padding: 10px;
    border: none;
    border-radius: 15px;
    width: 120px;
    transition: all 0.5s ease-out;
    background-color: #e6f1ff;
    margin-top: 35px;

    &:hover {
        color: white;
        background-color: black;
    }

`


const StornoBanner = () => {
    const { width } = useWindowContext()
    const [isHiden, setIsHiden] = useState(false)
    
    const handleAccept = () => {
        // Získání aktuálního data
        const currentDate = new Date();
    
        // Vytvoření objektu obsahujícího informace o souhlasu
        const consentData = {
          consentGiven: true,
          consentDate: currentDate.toISOString(), // Převedení data na řetězec ve standardním formátu
        };
    
        // Uložení objektu do localStorage
        localStorage.setItem('consentData', JSON.stringify(consentData));
        console.log(localStorage)
        setIsHiden(true)
        console.log(isHiden)
      };

      

  return (
    <BannerCont $isHiden={isHiden}>
        <FlexDiv>
            {width > 629 && <Alert /> }
            
            <TextCont>
            {width <= 629 && <Alert /> }
                <TextBanner>
                        <SpanWhite>Vážení zákazníci, informujeme vás o nové politice stornování. Při stornování služby za méně jak 24h před jejím termínem bude účtováno <Span>50 % z její ceny.</Span> Při další návštěvě bude účtována nová služba a 50 % z předchozí stornované služby. <br /> <br /> Tímto krokem usilujeme o zachování rovnováhy a zajištění kvalitního poskytování našich služeb.
                        Děkujeme za pochopení a těšíme se na vaši další návštěvu.</SpanWhite> <br /> <br />
                        <span style={{color: '#f5e194', fontSize: 'clamp(16px, 1.3vw, 20px)'}}>Kliknutím na tlačítko (Rozumím) potvrďte a přejděte k objednávce</span>
                </TextBanner>
                <Button onClick={()=> handleAccept()}>Rozumím</Button>
            </TextCont>
            
        </FlexDiv>
    </BannerCont>
  )
}

export default StornoBanner
