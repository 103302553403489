import Classic from '../../Img/klasickyStrih.png'
import Strojek from '../../Img/classic.png'
import BeardTrim from '../../Img/beardtrim.png'
import Klasika from '../../Img/klasika.png'
import Kombo from "../../Img/kombo.png"
import Shave from '../../Img/shave.png'
import Child from '../../Img/child.png'
import Max from '../../Img/max.png'
import Wax from '../../Img/wax.png'
// import Ear from '../../Img/ear.png'
import Mask from '../../Img/mask.png'
import ChildFoto from '../../Img/childFoto.png'
import UpravaVousu from '../../Img/upravaVousu.png'
import Kombinace from '../../Img/kombinace.png'
import HeadShave from '../../Img/headShave.png'
import FaceShave from '../../Img/faceShave.png'
import MAxGgirl from '../../Img/maxggirl.png'
import Waxing from '../../Img/waxing.png'
// import Burn from '../../Img/waxEars.png'
import Gold from '../../Img/gold.png'
import Gentle from '../../Img/gentle.png'
import Gentled from '../../Img/gentled.png'
import G10 from '../../Img/g10.JPG'
import Fade from '../../Img/Fade.png'
import FadePlus from '../../Img/FadePlus.png'
import FadePlusFoto from '../../Img/FadePlusFoto.png'
import FadeMax from '../../Img/FadeMax.png'
import FadeMaxPhoto from '../../Img/FadeMaxPhoto.png'




const services = [{
    
    Images: {
        Bg: Classic,
        flaticon: Klasika,

    },

    Header: {
        header: "Klasický střih",
        secondHeader: "Classic",
        idService: 58409
    },

    Desc: {
        top: "Objednejte si na míru šitý střih, provedený strojkem a nůžkami, s perfektní konturou.",
        middle: "Kompletní službu završíme mytím vlasů, úpravou obočí a profesionálním stylingem.",
        bottom: "Těšte se na 45 minut luxusní péče.",
        otherText: null,
    },

    CardDesc: {
        Cdesc: "Uvažujete nad klasickým sestřihem? Klikněte níže a vyberte termín",
        Price: "480,-",  

    }

},

{
    Images: {
        Bg: UpravaVousu,
        flaticon: BeardTrim,
  
    },

    Header: {
        header: "Úprava Vousů",
        secondHeader: "Beard Trim",
        idService: 58412
    },

    Desc: {
        top: "Připravte se na specializovanou péči o váš plnovous.",
        middle: "Proces zahrnuje zastřižení a použití horkého ručníku pro otevření pórů.",
        bottom: "Poté následuje zaholení kontur a aplikace hydratačního balzámu pro dokonalý výsledek. Toto vše v trvání 20 minut.",
        otherText: null,
    },

    CardDesc: {
        Cdesc: "Uvažujete nad klasickým sestřihem? Klikněte níže a vyberte termín",
        Price: "330,-", 

}},

{
    Images: {
        Bg: Kombinace,
        flaticon: Kombo,
  
    },

    Header: {
        header: "Kombo balíček",
        secondHeader: "Kombinace",
        idService: 58411
    },

    Desc: {
        top: "Kombinace je naše prémiová služba, která kombinuje naši oceňovanou službu Klasický střih a důkladnou Úpravu vousů.",
        middle: "Nabízíme vám dokonalý zážitek z péče o vlasy a vousy, vše za zvýhodněnou cenu.",
        bottom: "Můžete se těšit na 75 minut luxusní péče.",
        otherText: null,
    },

    CardDesc: {
        Cdesc: "Uvažujete nad kombinací služeb se slevou? Klikněte níže a vyberte termín",
        Price: "710,-",  

    }
},

{
    Images: {
        Bg: HeadShave,
        flaticon: Strojek,
  
    },

    Header: {
        header: "Holení hlavy",
        secondHeader: "Head Shave",
        idService: 58407
    },

    Desc: {
        top: "Holení hlavy strojkem je rychlá, efektivní metoda pro bezchybný vzhled.",
        middle: "Bez použití nůžek se dosahuje strojové preciznosti a jednotného střihu.",
        bottom: "Tuto službu realizuji během 20 minut.",
        otherText: null,
    },

    CardDesc: {
        Cdesc: "Uvažujete nad holením hlavy s použitím strojku? Klikněte níže a vyberte termín",
        Price: "330,-",  

    }
},

{
    Images: {
        Bg: FaceShave,
        flaticon: Shave,
  
    },

    Header: {
        header: "Holení tváře",
        secondHeader: "Face Shave",
        idService: 58408
    },

    Desc: {
        top: "Face Shave je dokonalá služba pro hladký vzhled.",
        middle: "Tento proces zahrnuje použití břitvy, aplikaci horkého ručníku pro maximální pohodlí a následné ošetření pleti.",
        bottom: "Tato komplexní péče o tvář trvá 20 minut.",
        otherText: null,
    },

    CardDesc: {
        Cdesc: "Uvažujete nad holením tváře? Klikněte níže a vyberte termín",
        Price: "290,-",  

    }
},

{
    Images: {
        Bg: ChildFoto,
        flaticon: Child,
  
    },

    Header: {
        header: "Dětský Střih",
        secondHeader: "Child's Haircut",
        idService: 58405
    },

    Desc: {
        top: "Naše služba Dětský střih je určena pro naše mladé zákazníky ve věku 5 až 12 let.",
        middle: "Poskytujeme individuálně šité střihy s použitím strojků a nůžek, a pokud je to potřeba, také umytí a vysušení vlasů.",
        bottom: "Tato pečlivě provedená služba trvá 30 minut.",
        otherText: null,
    },

    CardDesc: {
        Cdesc: "Uvažujete nad sestřihem pro dítě? Klikněte níže a vyberte termín",
        Price: "280,-",  

    }
},

{
    Images: {
        Bg: MAxGgirl,
        flaticon: Max,
  
    },

    Header: {
        header: "Barber Ggirl MAX",
        secondHeader: "Barber MAX",
        idService: 58020
    },

    Desc: {
        top: "Naše prémiová služba Barber Ggirl MAX nabízí celkovou péči o vaše vlasy a tvář.",
        middle: "Tato služba zahrnuje střih na míru, holení tváře s úpravou plnovousu, depilaci nosních chloupků a nakonec vyživující masku.",
        bottom: "Všechno toto je poskytnuto v rámci jedné seance trvající 70 minut.",
        otherText: null,
    },

    CardDesc: {
        Cdesc: "Uvažujete nad balíčkem BarberGgirl MAX? Klikněte níže a vyberte termín",
        Price: "910,-",  

    }
},

{
    Images: {
        Bg: Waxing,
        flaticon: Wax,
  
    },

    Header: {
        header: "Depilace Uší a Nosu",
        secondHeader: "Waxing",
        idService: 58404
    },

    Desc: {
        top: "Nabízíme depilaci uší a nosu pomocí horkého vosku.",
        middle: "Služba zajišťuje důkladné odstranění nežádoucích chloupků.",
        bottom: "Tato rychlá procedura trvá pouze 10 minut",
        otherText: null,
    },

    CardDesc: {
        Cdesc: "Uvažujete nad depilaci uší a nosu? Klikněte níže a vyberte termín",
        Price: "60,-",  

    }
},

// {
//     Images: {
//         Bg: Burn,
//         flaticon: Ear,
  
//     },

//     Header: {
//         header: "Opálení ušních chloupků",
//         secondHeader: "Ear hair singeing",
//         idService: 58410
//     },

//     Desc: {
//         top: "Opálení chloupků plamenem je specifická metoda odstraňování chloupků, která zahrnuje použití vatového tampónu namočeného v kolínské.",
//         middle: "Při této proceduře se chloupky odstraňují pomocí kontrolovaného spálení, což vytváří hladký vzhled.",
//         bottom: "Tento proces je velmi rychlý a trvá pouze 5 minut",
//         otherText: null,
//     },

//     CardDesc: {
//         Cdesc: "Uvažujete nad Opálením ušních chloupků? Klikněte níže a vyberte termín",
//         Price: "50,-",  

//     }
// },

{
    Images: {
        Bg: Gold,
        flaticon: Mask,
  
    },

    Header: {
        header: "Zlatá maska",
        secondHeader: "Gold Mask",
        idService: 58406
    },

    Desc: {
        top: "Nabízíme luxusní službu nanesení zlaté masky, která dodává pleti zářivý vzhled.",
        middle: "Po aplikaci masky následuje omytí obličeje a péče o pokožku, která zanechává pocit osvěžení.",
        bottom: "Tato ošetření trvají 10 minut a poskytují vám okamžitou péči a revitalizaci pokožky.",
        otherText: null,
    },

    CardDesc: {
        Cdesc: "Uvažujete nad Zlatou maskou? Klikněte níže a vyberte termín",
        Price: "85,-",  

    }
},

{
    Images: {
        Bg: Gentled,
        flaticon: Gentle,
  
    },

    Header: {
        header: "Holení hlavy s úpravou vousů",
        secondHeader: "Gentle Cut",
        idService: 58422
    },

    Desc: {
        top: "Holení hlavy s úpravou vousů je více než jen obyčejný střih.",
        middle: "Začínáme holením hlavy pro hladký a čistý vzhled.",
        bottom: "Následuje úprava vousů podle vašich představ, při které používáme kvalitní nástroje pro dokonalou preciznost. Vše za 45 minut.",
        otherText: null,
    },

    CardDesc: {
        Cdesc: "Uvažujete nad Holením hlavy s úpravou vousů? Klikněte níže a vyberte termín",
        Price: "440,-",  

    }
},

{
    Images: {
        Bg: G10,
        flaticon: Fade,
  
    },

    Header: {
        header: "Fade",
        secondHeader: "Fade",
        idService: 60402
    },

    Desc: {
        top: "Tento střih se zaměřuje na dokonalé zpracování boků vaší hlavy, kde vlasy postupně mizí v elegantním přechodu vytvořeným strojkem.",
        middle: "Naše služba nezahrnuje použití nůžek, ale poskytuje zvýraznění kontur, které dodá vaší vizáži osobitý rys.",
        bottom: "Každý střih završujeme pečlivým umytím a stylingem vlasů pro dokonalý vzhled. Tato služba trvá 25 minut.",
        otherText: null,
    },

    CardDesc: {
        Cdesc: "Uvažujete nad službou Fade? Klikněte níže a vyberte termín",
        Price: "380,-",  

    }
}, 

{
    Images: {
        Bg: FadePlusFoto,
        flaticon: FadePlus,
  
    },

    Header: {
        header: "Fade Plus",
        secondHeader: "Fade Plus",
        idService: 60403
    },

    Desc: {
        top: "Fade s vrchem hlavy je komplexní střih zahrnující vystříhaní boků strojkem, zvýraznění kontur, umytí, styling a úpravu vlasů na vrchu hlavy.",
        middle: "Tento komplexní střih, přináší dokonalou kombinaci elegance a trendového vzhledu.",
        bottom: "Celý proces střihu FadePlus zabere přibližně 40 minut, což zahrnuje jak samotné stříhání, tak i umytí a styling.",
        otherText: null,
    },

    CardDesc: {
        Cdesc: "Uvažujete nad službou Fade Plus? Klikněte níže a vyberte termín",
        Price: "430,-",  

    }
},

{
    Images: {
        Bg: FadeMaxPhoto,
        flaticon: FadeMax,
  
    },

    Header: {
        header: "Fade Max",
        secondHeader: "Fade Max",
        idService: 60404
    },

    Desc: {
        top: "Fade s vrchem hlavy a úpravou vousů je komplexnější služba",
        middle: "Zahrnuje vystříhaní boků strojkem, zvýraznění kontur, úpravu vlasů na vrchu hlavy, umytí a styling vlasů, a jako bonus úpravu vousů.",
        bottom: "Tento komplexní střih je dokončený v průběhu 50 minut.",
        otherText: null,
    },

    CardDesc: {
        Cdesc: "Uvažujete nad Službou Fade Max? Klikněte níže a vyberte termín",
        Price: "580,-",  

    }
},]

export default services