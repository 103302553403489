import { useRef, useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';
import services from './data'
import Signa from '../../Img/Signa.png'
import colors from "../../colors";
import Rezervation from "../MailerForm/Rezervation";
import { ServiceContext } from "../../props/ServicesId";
import { useWindowContext } from "../../props/ScrollWidth";
import CustomArrow from "./CustomArrow";
import "./Slick.css"






const styleClassicText = {
    fontSize: "20px",
    color: "rgb(213, 189, 99)",

}



const BtnDiv = styled.div`
  position: relative;
  z-index: 3;
  
`


const ServicesWrapper = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  height: 439px;
  
  border: 2px solid rgb(213, 189, 99);


 
`

const ClassicWrapper = styled.img`
  position: relative;
  right: 2.9%;
  z-index: 3;
  height: 100px;
  transform: rotate(0deg);
  position: initial;
  padding: 5px;

 
`

const ServicesText = styled.h2`
font-family: 'Playfair Display', serif;
 font-size: 12px;
 text-align:center;
 color: ${colors.white};
 z-index: 3;
 width: 145px;




`
const ResponziveDiv = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center space-around;
  width: 240px;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  z-index: 3;
  padding: 15px 0;






`

const ItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  height: 436px;
  width: 88.1%;
  justify-content: space-around;

  
 
`


const BackroundDiv = styled.div`
  background-image: url(${props => props.ServiceImage});
  background-repeat: no-repeat;
  background-position: 53%;
  background-size: cover;
  background-color: black;
  height: 405px;
  width: 88.1%;
  position: absolute;
  top: 15px;
  


 
`

const ItemCont = styled.div`
    position: relative;
    display: flex;
    justify-content: space-evenly;
    -webkit-box-align: center;
    align-items: center;
    width: 34%;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    height: 940px;
    box-shadow: rgb(34,34,34) 20px -1px 20px 3px;
    z-index: 4;

    @media(max-width: 1146px){
      width: 100%;
      height: 1157px;
      flex-direction: column;
    }

    
    

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-image: url(${props => props.bgData});
      background-repeat: no-repeat;
      background-position: 20%;
      background-size: cover;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    &::after {
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to right bottom, #2a1909, rgb(213 189 99), #816235, #ac8e4b, #d5bd63);
      position: absolute;
      z-index: 2;
     

    }

    

`

const SpanTime = styled.span`
  color: rgb(207 183 96);
  font-weight: 800;
  font-size: 20px;

  @media(max-width: 1146px){
    color: white;
    font-weight: 600;
  
  }

  
`

const WrapperTextCont = styled.div`
  ${[props => props.width <= 1446 ? null : "position: absolute;"]}
  display: flex;
  height: 439px;
  width: 584px;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: space-around;
  z-index: 5;
  left: 54%;
  top: 238px;



@media (max-width: 1600px) {
  left: 49%;
    
}

@media (max-width: 1146px){
  width: 100%;
  height: 590px;
}

`

const WrapperText = styled.p`
font-family: 'Playfair Display', serif;
  text-align: center;
  height: 396px;
  color: #e6f1ff;
  font-size: 20px;
  font-weight: 500;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

 
  @media(max-width: 1146px){
    color: rgb(255 238 176);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    font-weight: 700;
    width: 626px;
    padding: 50px;
    

  }

  @media (max-width: 580px) {
    height: 494px;
    width: 339px;
    font-size: 19px;
    padding: 25px;
  }


`

const SignaDiv = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height:100px;
  width: 593px;
  justify-content: center;


  @media(max-width: 1146px){
    width: 624px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.59);
    margin: 0 auto;
    border-radius: 15px;
  }

  @media(max-width: 580px){
    width: 73%;
    position: relative;
    bottom: 28px;
  }


 

`

const WrapperFlexDiv = styled.div` 
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: 449px;
    
`

const Signature = styled.img`
    width: 135px;

    @media(max-width: 580px){
      width: 62px;
    }
   
`

const ServicesName = styled.h2`
    font-family: 'Playfair Display', serif;
    color:  rgb(213 189 99);
    text-align: center;

    @media(max-width: 1146px){
      color:  ${colors.white};;
    }

    @media(max-width: 450px){
      font-size: 17px;
    }
  `

  const ServicesValue = styled.p`
    color: rgb(213, 189, 99);
    font-family: 'Playfair Display', serif;
    z-index: 3;
    position: relative;
    left: 2%;
  `

  const Span = styled.span`
  font-weight: 600;
    color: rgb(230, 241, 255);
    font-size: 18px;

    @media(max-width: 1146px){
      font-weight: 800;
      color: white;
      
    }
`


const StyledSlider = styled(Slider)`
    height: 938px;
    overflow: hidden;
    // background: linear-gradient(28deg,rgb(120, 106, 55) , rgb(13 13 13) 37%, rgb(13 13 13) 79%, rgb(120, 106, 55) 111%);
    background-color: rgb(51 50 48);
   
    @media(max-width: 1146px){
      height: 1158px;
    }

    
  .slick-dots {
    bottom: 300px;
    width: 49px;
    right: 69.8%;

   
    @media(max-width: 1146px){
      bottom: 595.8px;
      width: 100%;
      right: 0;
      
    }

    @media(max-width: 1128px){
      bottom: 781px;
      width: 100%;
      
    }

    @media(max-width: 1400px){
      bottom: 105.8px;
      width: 100%;
    }

    @media(max-width: 1300px){
      bottom: 66.8px;
      width: 100%;
      

      @media(max-width: 1146px){
        bottom: 574.8px;
        width: 100%;
        
        
        
      }

      
      @media(max-width: 580px){
        margin: 0px 0px;
        bottom: 517.8px;
        
        
      }

     

    }
    
    li {
      width: 20px;
      height: 20px;

      @media(max-width: 580px){
        margin: 0px 0px;
        
        
        
      }
      
      button {
        width: 20px;
        height: 20px;
        
        &:before {
          font-size: 18px;
          @media(max-width: 334px){
            font-size: 11px;
          }
        }
      }
    }

    .slick-active {
      button {
        &:before {
          color: #d5bd63;
        }
      }
    }
  }
`;

const SetServ = styled.button`
  height: 51px;
  border: none;
  background: none;
  width: 258px;
  
`

const ShadowWrapper = styled.div`
  z-index: 2;
  height: 405px;
  width: 88.1%;
  background-color: rgb(13, 13, 13);
  opacity: 0.7;
  position: absolute;
  top: 15px;
  left: 6%;



`






const Slick = () => {

  const { setServiceId } = useContext(ServiceContext)
  const { width } = useWindowContext()

 

  const sliderRef = useRef(null);

    
  const handleBeforeChange = () => {
    // Vypne autoplay, když uživatel ručně posune snímek
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };


   
  const handleServicesBtn= (idService)=> {
    setServiceId(idService)

  }

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    beforeChange: handleBeforeChange,
    
  };
      
  return (
    <div >
    <StyledSlider className="SliderStyled" ref={sliderRef} {...settings}>
        {services.map((service, index)=>{
          const {Images, Header, Desc, CardDesc} = service
            return (
            
            <div key={index}>
           

              {width <= 1146 ?
              null
               : <WrapperTextCont className='WrapperTextCont'>
                 
               <SignaDiv>
                               <Signature className="signa" src={Signa}></Signature>
                               <ServicesName>{Header.header}</ServicesName>
                               <Signature src={Signa}></Signature>
             </SignaDiv>
           <WrapperFlexDiv >
             <WrapperText>
               <Span>
                 {Desc.top}<br /> <br />
               </Span>

               {Desc.middle}<br /><br />
                 <SpanTime>
                   {Desc.bottom}
                 </SpanTime>
             </WrapperText>
           </WrapperFlexDiv>
           </WrapperTextCont>}


           

              

         

        <ItemCont bgData={Images.Bg} className='ItemCont'>

          
        {width <= 1146 ?
              <WrapperTextCont width={width} className='WrapperTextCont'>
                 
              <SignaDiv>
                              <Signature className="signa" src={Signa}></Signature>
                              <ServicesName>{Header.header}</ServicesName>
                              <Signature src={Signa}></Signature>
            </SignaDiv>
          <WrapperFlexDiv >
            <WrapperText>
              <Span>
                {Desc.top}<br /> <br />
              </Span>

              {Desc.middle}<br /><br />
                <SpanTime>
                  {Desc.bottom}
                </SpanTime>
            </WrapperText>
          </WrapperFlexDiv>
          </WrapperTextCont>
               : null}
        

            <ServicesWrapper width={width}  className='ServicesWrapper'>   
              
                  <ItemDiv  className='ItemDiv' >

                           <ResponziveDiv  className='ResponziveDiv'>
                            <BackroundDiv ServiceImage={Images.Bg} className="bgDiv"></BackroundDiv>
                            <ShadowWrapper className="Shadow"></ShadowWrapper>
                              
                                <ClassicWrapper className='ClassicWrapper' src={Images.flaticon}></ClassicWrapper>
                                  <ServicesText style={styleClassicText}>{Header.secondHeader}</ServicesText>
                                  <ServicesText>{CardDesc.Cdesc}</ServicesText>
                                    <BtnDiv>
                                      <SetServ onClick={()=> handleServicesBtn(Header.idService)}><Rezervation /></SetServ>
                                    </BtnDiv>
                                <ServicesValue>{CardDesc.Price}</ServicesValue>
                           </ResponziveDiv> 
                           
                           
                    
                          
                            
                  </ItemDiv>
              </ServicesWrapper>

          </ItemCont>

              


             </div> 
        )})}

    </StyledSlider>
    </div>
  )
}

export default Slick


