import { useState, useEffect }from 'react'
import styled from 'styled-components'
import Images from './imgG'
import { Tilt } from 'react-tilt'
import colors from '../../colors'
import { useWindowContext } from '../../props/ScrollWidth'






const FlexCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;

`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); // sloupce se automaticky přizpůsobí šířce okna prohlížeče
  gap: 108px;
  width: 100%; // Nastavte šířku na 100%, aby se grid mohl rozšířit
  height: 100%; // Nastavte výšku na 100%, aby se grid mohl rozšířit
  justify-items: center;
  margin: 100px
    
`;

const Cell = styled.div`
  background-color: #f0f0f0;
  border: 1px solid #ccc; 
  padding: 20px; 
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: ${props => props.position || 'center'};
  background-repeat: no-repeat;
  width: 400px;
    height: 400px;
`;

const GaleryText = styled.h2`
  font-family: 'Playfair Display', serif;
  font-size: 35px;
  letter-spacing: 9px;
  color: #e6f1ff;

`
const FlexText = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 180px;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: #0d0d0d;


`

const ButtonDivCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`


const ButtonDiv = styled.div`
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  width: 830px;
  height: 149px;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: relative;
  bottom: 50px;
  
  
  

  &::before {
    content: "";
    height: 347.7px;
    position: absolute;
    z-index: 1;
    background-color: ${colors.Black};
    height: 100px;
    width: 321.1px;
    opacity: 0.99;
    transition: background-color 500ms ease-out;
    width: ${[props=> props.isHover ? "80%" : null]};
    transition: width 500ms ease-out;
    
    
    
  }
  
  &:hover::before {
    background-color: rgba(4, 3, 0, 0.81);
    
  }
`;

const ButtonSlice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  positon: relative;
  z-index: 2;
  bottom: 49px;
  width: 24%;
  text-align: center;
  color: ${[props => props.isHover ? `${colors.barber};` : `${colors.white};`]};
  transition: color 500ms ease-out; 
  letter-spacing: 5px;

  
  

  @media(max-width: 829px) {
    width: 100%;
  }

  

;
`





const Galerie = () => {
  const [showMore, setShowMore] = useState(false);
  const [isHover, setOnHover] = useState(false);
  const [numImages, setNumImages] = useState(8);
  const { width } = useWindowContext();

  useEffect(() => {
    if (width <= 1651 && width > 1244) {
        setNumImages(12);
    } else if (width <= 1244 && width > 835) {
        setNumImages(10);
    } else if(width <= 835){
        setNumImages(5);
    }
    
}, [width]);



  


  
  const handleClick = ()=> {
    setShowMore((v)=>!v)
    setOnHover((v)=>!v)
  }
  

  const handleMouseEnter = ()=> {
      setOnHover(true)
      
  }

  const handleMouseLeave = ()=> {
      setOnHover((v) => !v)
      

  }

  

  return (
    <div style={{overflow: "hidden"}}>
      <FlexText>
        <GaleryText>Galerie</GaleryText>
      </FlexText>
      <FlexCont >
        <Grid>
          {Images.slice(0, showMore ? Images.length : numImages).map((imgUrl, i) => (
            <Tilt options={{ max: 25, scale: 1.1, speed: 1000, }} style={{ height: 400, width: 400 }} key={i}>
              <Cell background={imgUrl} />
            </Tilt>
          ))}
        </Grid>
      </FlexCont>
      
      {!showMore && 
                  <ButtonDivCont>
                    <ButtonDiv
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      className='buttonDiv'
                      onClick={handleClick}
                      isHover={isHover}
                    >
                          <ButtonSlice 
                            isHover={isHover}
                            style={{cursor: "pointer"}}
                          >
                            <div>Zobrazit více</div>
                          </ButtonSlice>
                            
                    </ButtonDiv>
                  </ButtonDivCont>
                  }

                  {showMore && 
                    <ButtonDivCont
                    >
                      <ButtonDiv
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className='buttonDiv'
                        onClick={handleClick}
                        isHover={isHover}
                        showMore={showMore}
                      >
                            <ButtonSlice 
                              isHover={isHover}
                              style={{cursor: "pointer"}}
                            >
                              <div>Zobrazit méně</div>
                            </ButtonSlice>
              
                      </ButtonDiv>
                    </ButtonDivCont>
                  }  
    </div>
  );
};

export default Galerie;
