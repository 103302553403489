import styled from "styled-components"


const FlexCont = styled.div`
    width: 100%;
    height: 1151px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(4 3 0 / 81%);
    flex-direction: column;
`

const Iframe = styled.iframe`
    width: 90%;
    height: 712px;
`

const TextDiv = styled.div`
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 200px;
    width: 100%;
    background-color: rgb(13 13 13);
`

const Text = styled.h2`
    font-family: "Playfair Display", serif;
    font-size: 35px;
    letter-spacing: 5px;
    color: #e6f1ff;

    @media (max-width: 384px) {
      font-size: 19px;
    }
`

const Map = () => {
  return (
    <>
    <TextDiv><Text>Kde nás najdete</Text></TextDiv>
    <FlexCont>
      <Iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2580.3679762130123!2d17.07375117695673!3d49.703873740836656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471240e05903c4f9%3A0x97b17c4ce770852a!2zVsOtdMSbem7DoSAxMDYvMTAsIDc4NCAwMSBMaXRvdmVs!5e0!3m2!1scs!2scz!4v1685999764591!5m2!1scs!2scz"
        width="600"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade">
        </Iframe>
    </FlexCont>
    </>
  )
}

export default Map
