import * as React from "react";
const BarberAlert = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={80}
    zoomAndPan="magnify"
    viewBox="0 0 60 60"
    height={80}
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <defs>
      <clipPath id="28e737f967">
        <path
          d="M 0.03125 2.738281 L 59.964844 2.738281 L 59.964844 57.484375 L 0.03125 57.484375 Z M 0.03125 2.738281 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="713035433c">
        <path
          d="M 0.828125 5.671875 L 59.964844 5.671875 L 59.964844 57.484375 L 0.828125 57.484375 Z M 0.828125 5.671875 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#28e737f967)">
      <path
        fill="#ea1135"
        d="M 0.820312 48.421875 L 24.726562 5.832031 C 27.039062 1.707031 32.957031 1.707031 35.273438 5.832031 L 59.175781 48.421875 C 61.449219 52.472656 58.535156 57.484375 53.902344 57.484375 L 6.09375 57.484375 C 1.460938 57.484375 -1.453125 52.472656 0.820312 48.421875 "
        fillOpacity={1}
        fillRule="nonzero"
      />
    </g>
    <g clipPath="url(#713035433c)">
      <path
        fill="#c4021a"
        d="M 59.175781 48.421875 L 35.273438 5.832031 C 34 3.5625 53.300781 45.382812 53.300781 45.382812 C 55.574219 49.433594 52.660156 54.449219 48.027344 54.449219 L 0.851562 54.449219 C 1.875 56.21875 3.765625 57.484375 6.09375 57.484375 L 53.902344 57.484375 C 58.535156 57.484375 61.449219 52.472656 59.175781 48.421875 "
        fillOpacity={1}
        fillRule="nonzero"
      />
    </g>
    <path
      fill="#ffffff"
      d="M 30 41.773438 C 28.140625 41.773438 26.632812 43.289062 26.632812 45.15625 C 26.632812 47.023438 28.140625 48.539062 30 48.539062 C 31.859375 48.539062 33.367188 47.023438 33.367188 45.15625 C 33.367188 43.289062 31.859375 41.773438 30 41.773438 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 30 14.722656 C 27.046875 14.722656 24.734375 17.265625 25 20.21875 L 26.328125 35.03125 C 26.5 36.9375 28.09375 38.398438 30 38.398438 C 31.90625 38.398438 33.496094 36.9375 33.667969 35.03125 L 35 20.21875 C 35.261719 17.265625 32.949219 14.722656 30 14.722656 "
      fillOpacity={1}
      fillRule="nonzero"
    />
  </svg>
);
export default BarberAlert;
