import styled from "styled-components"
import end from '../../Img/end.png'



const FlexCont = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
    background-color: black;
    background-image: url(${end});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
    border-top: 2px solid rgb(213, 189, 99);

    @media (max-width: 1300px){
      background-size: 100%;
    }

    @media (max-width: 580px){
      background-size: 800px;
    }
`


const CopyMark = styled.h2`
font-family: "Playfair Display", serif;
    color: rgb(230, 241, 255);
    text-align: center;
    font-size: 24px;
    padding: 15px;
    width: 719px;
    backdrop-filter: blur(25px);
    border-left: 6px solid rgb(213, 189, 99);
    border-right: 6px solid rgb(213, 189, 99);
    
    @media (max-width: 755px) {
    width: 100%;
    }

    @media (max-width: 500px){
      font-size: 19px;
    }

    

    `
const End = () => {
  return (
    <FlexCont>
      <CopyMark>Vstupte do světa, kde se kvalita setkává s vášní a kde je každý detail důležitý.<br/><br/> <span style={{color: "#d5bd63"}}>Těšíme se na vaši návštěvu.</span></CopyMark>
    </FlexCont>
  )
}

export default End
