import React from 'react';


const CustomArrow = ({ direction, onClick }) => {
  let className = '';
  let char = '';

 
  if (direction === 'next') {
    className = 'slick-next slick-arrow';
    char = '>';  
  } else {
    className = 'slick-prev slick-arrow';
    char = '<';  
  }

  
  return (
    <button className={className} onClick={onClick}>
      {char}
    </button>
  );
};

export default CustomArrow;