import React, { createContext, useContext, useState, useEffect } from 'react';

const WindowContext = createContext();

export function WindowContextProvider({ children }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [width, scrollPosition]);

  const value = { width, scrollPosition };

  return (
    <WindowContext.Provider value={value}>
      {children}
    </WindowContext.Provider>
  );
}

export const useWindowContext = () => {
  return useContext(WindowContext);
};