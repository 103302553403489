import React from 'react'
import styled from 'styled-components'
import Wood from '../../Img/wood.png'
import colors from '../../colors'
import Aos from 'aos'
import { useEffect } from 'react'
import Alogo from '../../Img/Alogo.png'


// import Kriz from '../../Img/kriz.png'
// import { useState, useEffect } from 'react'


const WoodCont = styled.div`
  position: relative;
  display: flex;
  -webkit-box-pack: start;
  justify-content: center;
  -webkit-box-align: end;
  align-items: end;
  flex-direction: column;
  background-color: ${colors.Black};
  background-image: url(${Wood});
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;
    
    height: 1609px;

  @media (max-width: 1323px) {
    height: 2085.1px;
  }

  @media (max-width: 920px){
      height: 2508.1px;
   }

   @media (max-width: 510px){
      width: 100%;

   }
  `
// const DivMen = styled.div`
  
//   `


const ShadowWood = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(13 13 13);
  opacity: 0.8;
  position: absolute;

  @media (max-width: 1323px) {
    height: 2085.1px;
   }

   @media (max-width: 920px){
    height: 2508.1px;
 }

 

`
const Desc = styled.div` 
  font-family: 'Playfair Display', serif;
  display: flex;
  flex-flow: column wrap;
  width: 1514px;
  border-right: 2px ridge rgba(213, 189, 99, 0.38);
  border-left: 2px ridge rgba(213, 189, 99, 0.38);
  border-image: initial;
  line-height: 26px;
  height: 1253.2px;
  place-content: stretch space-around;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  border-top: none;
  border-bottom: none;
  align-content: center;


    @media (max-width: 1323px) {
      height: 2084.7px;
    }

    @media (max-width: 550px){

      width: 100%;
    }
    

`



const SpanFont = styled.span`
  color: rgb(213, 189, 99);
`
const BarberFont = styled.span`
  color: rgb(213 189 99);
  font-size: 20px;
  @media (max-width: 553px) {
    font-size: 20px;
  }
  
`

const P = styled.p`
  color: rgb(230, 241, 255);
  font-size: 15px;
  width: 927px;
  backdrop-filter: blur(20px);
  padding: 20px;
  border-right: 6px solid #d5bd63;
  border-left: 6px solid #d5bd63;
  text-align: center;

  @media (max-width: 960px) {
    width: 50%;
  }

  @media (max-width: 553px) {
    font-size: 20px;
  }


  @media (max-width: 590px) {
    width: 100%
    
  }

`
const CopyMark = styled.h2`
color: rgb(230, 241, 255);
text-align: center;
font-size: 20px;
padding: 15px;
width: 719px;
backdrop-filter: blur(25px);
border-left: 6px solid rgb(213, 189, 99);
border-right: 6px solid rgb(213, 189, 99);
  
@media (max-width: 755px) {
  width: 100%;
}

`



const DescCont = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    width: 100%;
    flex-wrap: wrap;
    
`

const Barber = styled.div`
  height: 416px;
  background-image: url(${Alogo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index:2;
    width: 469px;

    @media (max-width: 510px){
      width: 100%;

   }
  
`





const PoznejteMe = () => {
  
  useEffect(()=>{
    Aos.init({
      duration: 2000,
      once: true
    })
  },[])



  return (
    <WoodCont>
      
      <ShadowWood>
          
      </ShadowWood>

      <DescCont>
      <Desc>
     

        <Barber data-aos="zoom-in" className='Barber'>
            
        </Barber> 
            <P data-aos="fade-up-right" className='p'><BarberFont className='BarberFont'>
              Hledáte nejen kadeřnictví, ale zážitek, který překračuje hranice obvyklé péče o vlasy? pak BarberGgirl je vaše destinace. <br /><br/><span>
              Nejde tu jen o prvotřídní kadeřnické služby pro pány. Je to místo, kde můžete uniknout stresu a obnovit svou energii v prostředí, které ztělesňuje tradiční holičství.</span>
               </BarberFont><br /><br />
              <span style={{color: "rgb(213, 189, 99)"}}>V BarberGirl se neustále snažíme vytvářet prostředí, kde se budete cítit jako doma, ale zároveň budete moci ocenit ojedinělou péči a luxusní služby, které nabízíme. 
              </span>
               </P>
               <CopyMark data-aos="fade-up-left" className='CopyMark'><SpanFont>Jste frustrovaní z dlouhých cest za kvalitním střihem? Navštivte nás v srdci města Litovel, a my splníme vaše přání.</SpanFont></CopyMark>
          </Desc>
        
      </DescCont>
    </WoodCont>
        
    
  )
}

export default PoznejteMe
