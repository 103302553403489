
import styled from 'styled-components'
import colors from '../../colors'

import { useReservation } from '../../props/ReservationContext'



    const Click = styled.a`
      
      font-family: "Playfair Display", serif;
      font-size: 18px;
      font-weight: 800;
      padding: 9px 20px;
      background: rgb(230, 241, 255);
      border: none;
      border-radius: 2px;
      text-decoration: none;
      color: rgb(145, 118, 88);
        transition: background 0.5s ease, color 0.2s ease, 0.4s ease;
        cursor: pointer;
      

      &:hover {
        background: ${colors.barber};
        color: ${colors.white};
    
      }
        
    `

    const ClickCont = styled.div`
     width: 272px;
     text-align: center;
     position: relative;
     right: 5px;
   
    `

  
const Rezervation = () => {
  const { HandleClick } = useReservation()


  

  return (
      <ClickCont>
        <Click onClick={HandleClick}>Rezervovat</Click>
      </ClickCont>
        
     
        
    
  )
}

export default Rezervation 
