import G1 from '../../Img/g1.png'
import G2 from '../../Img/g2.png'
import G3 from '../../Img/g3.png'
import G4 from '../../Img/g4.png'
// přidat zde //
import G6 from '../../Img/g6.png'
import G7 from '../../Img/g7.png'
import G8 from '../../Img/g8.png'
import G9 from '../../Img/g9.png'
import G10 from '../../Img/g10.JPG'
import G11 from '../../Img/g11.JPG'
import G12 from '../../Img/g12.JPG'
import G13 from '../../Img/g13.JPG'
import G14 from '../../Img/g14.JPG'
import G15 from '../../Img/g15.JPG'
import G16 from '../../Img/g16.JPG'
import G17 from '../../Img/g17.JPG'
import G18 from '../../Img/g18.JPG'
import G19 from '../../Img/g19.JPG'
import G20 from '../../Img/g20.JPG'
import G21 from '../../Img/g21.JPG'
import G22 from '../../Img/g22.JPG'
import G24 from '../../Img/g24.JPG'



 const Images = [
    G1,
    G2,
    G3,
    G4,
    G6,
    G7,
    G8,
    G9,
    G10,
    G11,
    G12,
    G13,
    G14,
    G15,
    G16,
    G17,
    G18,
    G19,
    G20,
    G21,
    G22,
    G24,

]

export default Images