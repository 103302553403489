import { createRoot } from 'react-dom/client';
import { WindowContextProvider } from './props/ScrollWidth';
import { ReservationProvider } from "./props/ReservationContext"
import { ScrollContextProvider } from './props/Ref';
import { ServiceProvider } from './props/ServicesId';
import App from './App';

import './index.css';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ServiceProvider>
    <ScrollContextProvider>
      <WindowContextProvider>
        <ReservationProvider>
          <App></App>
        </ReservationProvider>
      </WindowContextProvider>
    </ScrollContextProvider>
  </ServiceProvider> 
  
)