import React, { createContext, useRef } from 'react';

export const HeroRefContext = createContext();
export const AboutRefContext = createContext();
export const ServicesRefContext = createContext();
export const GalleryRefContext = createContext();

export const ScrollContextProvider = ({ children }) => {
  const heroRef = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const galleryRef = useRef(null);

  return (
    <HeroRefContext.Provider value={heroRef}>
      <AboutRefContext.Provider value={aboutRef}>
        <ServicesRefContext.Provider value={servicesRef}>
          <GalleryRefContext.Provider value={galleryRef}>
            {children}
          </GalleryRefContext.Provider>
        </ServicesRefContext.Provider>
      </AboutRefContext.Provider>
    </HeroRefContext.Provider>
  );
}