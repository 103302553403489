import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useReservation } from '../../props/ReservationContext'
import colors from '../../colors'
import { Transition } from 'react-transition-group'
import { ServiceContext } from '../../props/ServicesId'
import StornoBanner from './StornoBanner'

const TestIframe = styled.iframe`
    width: 100%;
    left: 25%;
    height: 100vh;
`

const ContFlex = styled.div`
  width: 50%;

  @media (max-width: 980px) {
    width: 100%;
  }
`

const ShadowDiv = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: transparent;
`;


const Burger = styled.div`
  position: absolute;
  top: 16px;
  left: 70.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0px;
  z-index: 10;



  div {
    width: 2rem;
    height: 0.25rem;
    background: ${colors.Black};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: rotate(45deg);
    }
    :nth-child(2) {
      transform: rotate(-45deg);
      top: 5px;
    }
  }
  @media (max-width: 1110px) {
    left: 69%;
    

  }
  @media (max-width: 980px) {
    left: 92%;
    

  }
 }
`
  
const duration = 1000;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}



const transitionStyles = {
  entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
};


const Form = () => {

  const { serviceId } = useContext( ServiceContext )
  const { isHiden, HandleClick } = useReservation()
  const iframeURL = `https://services.bookio.com/barberggirl-i67vxyxv/widget?lang=cs&service=${serviceId}`;
  const consentDataString = localStorage.getItem('consentData');
  const consentData = consentDataString ? JSON.parse(consentDataString) : null;
  const [isPassed, setIsPassed] = useState(false)
  
  useEffect(() => {
    const isShortTimePassed = (consentDate) => {
      if (!consentDate) {
        return false;
      }

      let pocetDni = 30;
      const oneSecondInMillis = pocetDni * 24 * 60 * 60 * 1000;
      const consentTime = new Date(consentDate).getTime();
      const currentTime = new Date().getTime();

      return currentTime - consentTime >= oneSecondInMillis;
    };
    
    // Pro testování, zda uběhl měsíc od souhlasu
    if (consentData?.consentGiven && isShortTimePassed(consentData?.consentDate)) {
      setIsPassed(true);
    } else {
      setIsPassed(false);
    }
  }, [consentData?.consentGiven, consentData?.consentDate, consentData]);

    return (
     
          <div>
            
             <Transition in={!isHiden} timeout={duration} unmountOnExit>
                {state => (
                    <ShadowDiv style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                    }}>
                        <ContFlex>
                          {isPassed || !consentData ? <StornoBanner/> : null }
                            <Burger onClick={HandleClick}>
                                <div />
                                <div />
                            </Burger>
                            <TestIframe 
                                id="bookio-iframe" 
                                src={iframeURL} 
                                width="100%" 
                                onload="initBookioWidget()"
                                scrolling="yes"
                            />
                        </ContFlex>
                    </ShadowDiv>
                )}
            </Transition>
        </div>
                
                
    )
  }

  export default Form
