import React from 'react'
import styled from 'styled-components'

const FlexDiv = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000e6;
    color: #e6f1ff;
    font-size: 12px;
`

const P = styled.p`
    font-family: 'Playfair Display', serif; 
`


const Link = () => {
  return (
    <FlexDiv>
        <P>Vytvořilo studio OnCode.cz</P>
    </FlexDiv>
  )
}

export default Link
