import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'


const H1 = styled.h1`
    font-size: clamp(20px, 4vw, 30px);
    font-family: 'Roboto Mono';
`

const H2 = styled.h2`
    font-size: clamp(20px, 4vw, 25px);
    font-family: 'Roboto Mono'
`

const Text = styled.p`
    font-size: clamp(10px, 4vw, 16px);
    font-family: 'Roboto';
   
`

const TextCont = styled.div`
    display: flex;
    flex-direction: column;
    height: 1401px;
    justify-content: space-around;
    background: black;
    color: white;
    padding: 40px;

    @media(max-width: 1021px) {
        height: 2300px;
    }

    @media(max-width: 500px) {
        height: 3000px;
    }


    @media(max-width: 432px) {
        height: 3500px;
    }
    
`

const Styleda = styled(Link)`
    text-decoration: none;
    width: 220px;
    
    color: #d5bd63;
    font-size: clamp(10px, 4vw, 15px);
    font-family: 'Roboto';
    transition: 0.5s ease-out;

    &:hover{
        color: white;
    }
`

const GDPR = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
   
    
        <TextCont>
            <Styleda to="/">Zpět na domovskou stránku</Styleda>
            <H1>ZÁSADY ZPRACOVÁVÁNÍ A OCHRANY OSOBNÍCH ÚDAJŮ{" (GDPR)"}</H1>
            <H2>I. Základní ustanovení</H2>
            <Text>Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 o
                        ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů
                        (dále jen: „GDPR”) je Tereza Gargošová IČ 19324979, se sídlem Litovel, Vítězná 106/10 , 78401 (dále
                        jen: „správce“).
                        Kontaktní údaje správce jsou
                        adresa: Vítězná 106/10, 7841 Litovel
                        email: terezstarmi@seznam.cz
                        telefon: 605 803 379
                        Osobními údaji se rozumí veškeré informace o identifikované nebo identifikovatelné fyzické osobě;
                        identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat,
                        zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový
                        identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické,
                        ekonomické, kulturní nebo společenské identity této fyzické osoby.
                        Správce nejmenoval pověřence pro ochranu osobních údajů.
                        II. Zdroje a kategorie zpracovávaných osobních údajů
                        Správce zpracovává osobní údaje, které jste mu poskytl/a nebo osobní údaje, které správce získal na
                        základě plnění Vaší objednávky.
                        Správce zpracovává Vaše identifikační a kontaktní údaje a údaje nezbytné pro plnění smlouvy.
                        III. Zákonný důvod a účel zpracování osobních údajů
                        Zákonným důvodem zpracování osobních údajů je
                        plnění smlouvy mezi Vámi a správcem podle čl. 6 odst. 1 písm. b) GDPR,
                        oprávněný zájem správce na poskytování přímého marketingu (zejména pro zasílání obchodních
                        sdělení a newsletterů) podle čl. 6 odst. 1 písm. f) GDPR,
                        Váš souhlas se zpracováním pro účely poskytování přímého marketingu (zejména pro zasílání
                        obchodních sdělení a newsletterů) podle čl. 6 odst. 1 písm. a) GDPR ve spojení s § 7 odst. 2 zákona č.
                        480/2004 Sb., o některých službách informační společnosti v případě, že nedošlo k objednávce zboží
                        nebo služby.
                        Účelem zpracování osobních údajů je
                        vyřízení Vaší objednávky a výkon práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a
                        správcem; při objednávce jsou vyžadovány osobní údaje, které jsou nutné pro úspěšné vyřízení
                        objednávky (jméno a adresa, kontakt), poskytnutí osobních údajů je nutným požadavkem pro
                        uzavření a plnění smlouvy, bez poskytnutí osobních údajů není možné smlouvu uzavřít či jí ze strany
                        správce plnit,
                        zasílání obchodních sdělení a činění dalších marketingových aktivit.
                        Ze strany správce nedochází k automatickému individuálnímu rozhodování ve smyslu čl. 22 GDPR. S
                        takovým zpracováním jste poskytl/a svůj výslovný souhlas.
            </Text>
            <H2>IV. Doba uchovávání údajů</H2>
            <Text>Správce uchovává osobní údaje
                    po dobu nezbytnou k výkonu práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a
                    správcem a uplatňování nároků z těchto smluvních vztahů (po dobu 15 let od ukončení smluvního
                    vztahu).
                    po dobu, než je odvolán souhlas se zpracováním osobních údajů pro účely marketingu, nejdéle 5 let,
                    jsou-li osobní údaje zpracovávány na základě souhlasu.
                    Po uplynutí doby uchovávání osobních údajů správce osobní údaje vymaže.
                    V. Příjemci osobních údajů (subdodavatelé správce)
                    Příjemci osobních údajů jsou osoby
                    podílející se na dodání zboží / služeb / realizaci plateb na základě smlouvy,
                    (dále jen: „www.bookio.com“) zajišťující služby provozování holičství BarberGgirl a další služby v
                    souvislosti s provozováním e-shopu,
                    zajišťující marketingové služby.
                    Správce nemá v úmyslu předat osobní údaje do třetí země (do země mimo EU) nebo mezinárodní
                    organizaci.
            </Text>
            <H2>VI. Vaše práva</H2>
            <Text>Za podmínek stanovených v GDPR máte:
                <ul style={{marginLeft: "20px", marginTop: "12px", marginBottom:  "12px"}}>
                    <li>právo na přístup ke svým osobním údajům dle čl. 15 GDPR,</li>
                    <li>právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení zpracování dle čl. 18 GDPR.</li>
                    <li>právo na výmaz osobních údajů dle čl. 17 GDPR.</li>
                    <li>právo vznést námitku proti zpracování dle čl. 21 GDPR </li>
                    <li>právo na přenositelnost údajů dle čl. 20 GDPR.</li>
                    <li>právo odvolat souhlas se zpracováním písemně nebo elektronicky na adresu nebo email správce
                        uvedený v čl. III těchto podmínek.</li>
                </ul>   
                    Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že
                    bylo porušeno Vaší právo na ochranu osobních údajů.
            </Text>
            <H2>VII. Podmínky zabezpečení osobních údajů</H2>
            <Text>Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k zabezpečení osobních
                    údajů. Správce přijal technická opatření k zabezpečení datových úložišť a úložišť osobních údajů v listinné podobě.
                    Správce prohlašuje, že k osobním údajům mají přístup pouze jím pověřené osoby.
            </Text>
            <H2>VIII. Závěrečná ustanovení</H2>
            <Text>Odesláním objednávky z internetového objednávkového formuláře potvrzujete, že jste seznámen/a s
                    podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.
                    S těmito podmínkami souhlasíte zaškrtnutím souhlasu prostřednictvím internetového formuláře.
                    Zaškrtnutím souhlasu potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v
                    celém rozsahu přijímáte.
                    Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních údajů zveřejní
                    na svých internetových stránkách a zároveň Vám zašle novou verzi těchto podmínek Vaši e-mailovou
                    adresu, kterou jste správci poskytl/a. Tyto podmínky nabývají účinnosti dnem 1.5.2023.
            </Text>
        </TextCont>
   
  )
}

export default GDPR
