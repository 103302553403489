import styled from 'styled-components';
import FooterLogo from '../../Img/footerLogo.png';
import Facebook from '../../Img/facebook.png';
import Instagram from '../../Img/instagram.png';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { 
  HeroRefContext, 
  AboutRefContext, 
  ServicesRefContext, 
  GalleryRefContext 
} from '../../props/Ref';
// import { useWindowContext } from '../../props/ScrollWidth';

const GDPR = styled(Link)`
  text-decoration: none;
  color: #d5bd63;
  cursor: pointer;
  transition: 1ms ease-out;
  font-family: "Playfair Display", serif;
  font-size: 15px;
  transition: 0.3s ease-out;

  &:hover {
    color: #e6f1ff;
    
  }
`

const NavCont = styled.footer`
  display: flex;
  place-content: flex-end space-around;
  border-top: 2px solid rgb(213, 189, 99);
  height: 364px;
  background-color: rgb(51, 50, 48);
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: center;
  align-content: stretch;

  @media (max-width: 1170px) {
    height: 624px;
  }

  @media (max-width: 664px) {
    height: 955px;
  }

  

  
`

const FirstCont = styled.div`
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 950px;
  flex-wrap: wrap;
  @media (max-width: 390px;) {
    width: 100%;
  }

  
`

const SecondCont = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  width: 221px;
  
  

  @media (max-width: 1170px)  {
    right: 0;
  }
`

const ImgCont = styled.div`
  
`


const IMG = styled.img`
  height: 345px;
  position: relative;
  top: 17px;

  @media (max-width: 390px)  {
    height: 245px;
  }
 
`


const UlDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 310px;
  
  
  @media (max-width: 340px)  {
    position: relative;
    right: 4px;
    font-size: 15px
  }

`

const UL = styled.ul`
  display: flex;
  width: 387px;
  -webkit-box-pack: center;
  flex-direction: column;
  justify-content: space-evenly;
  -webkit-box-align: center;
  align-items: center;
  height: 180px;
  padding-left: 48px;
  border-left: 2px solid #d5bd63;

  @media (max-width: 930px){
    width: 300px;
  }

  @media (max-width: 644px){
    border-right: 2px solid #d5bd63;
    width: 387px;
    padding: 0;
  }

  @media (max-width: 390px)  {
    width: 340px;
  }

  @media (max-width: 340px)  {
    width: 300px;
  }
  
`
const Li = styled.li`
  margin-left: 15px;
  color: rgb(230, 241, 255);
  letter-spacing: 3px;
  font-family: "Playfair Display", serif;
  list-style: none;
  cursor: pointer;
  transition: color 0.3s ease;

  @media (max-width: 644px){
    margin-left: 0px;
    width: 100%;

  }

  

  &:hover {
    color: rgb(213, 189, 99);
  }

`

const CopyWrite = styled.p`
  color: rgb(213 189 99);
  font-family: "Playfair Display", serif;
  font-size: 14px;
  position: relative;
  left: 36px;

  @media (max-width: 644px){
    left: 31px;

  }
`
const LinkDiv = styled.div`
  display: flex;
  width: 220px;
  border-top: 1px solid rgb(213, 189, 99);
  
`

const Social = styled.img`
  height: 65px;
  transition: transform 0.6s ease;

  &:hover {
    transform: scale(1.3);
  }

`
const TextKontakt = styled.p`
  padding: 5px;
  text-align: center;
  font-family: "Playfair Display", serif;
  color: rgb(230, 241, 255);
  font-size: 13px;
  
`

const KontaktDiv = styled.div`
  margin-bottom: 20px;


`

const NavItems = styled.a`

`




const Footer = () => {
  // const { width, scrollPosition } = useWindowContext()


  const heroRef = useContext(HeroRefContext);
  const aboutRef = useContext(AboutRefContext);
  const servicesRef = useContext(ServicesRefContext);
  const galleryRef = useContext(GalleryRefContext);

  const SetHandleHero = () => {
    heroRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const setHandleAbout = () => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const setHandleServices = () => {
    servicesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const setHandleGalery = () => {
    galleryRef.current.scrollIntoView({ behavior: "smooth" });
  };
  
  return (
    <NavCont className='navcont'>
      <FirstCont className='firstcont'>
        <ImgCont className='imgcont'>
          <IMG className='IMG' src={FooterLogo}></IMG>
        </ImgCont>
        
          <UL className='Ul'>
            <UlDiv style={{justifyContent: "start"}}>
              <Li style={{textAlign: "center"}}><NavItems onClick={SetHandleHero}>Úvod</NavItems></Li>
              <Li><NavItems onClick={setHandleAbout}>O nás</NavItems></Li>
              <Li><NavItems onClick={setHandleServices}>Služby</NavItems></Li>
              <Li><NavItems onClick={setHandleGalery}>Galerie</NavItems></Li>
            </UlDiv>
            <UlDiv>
              <CopyWrite>©2023 barberggirl.cz All rights reserved</CopyWrite>
            </UlDiv>
          </UL>
      </FirstCont>
      <SecondCont className='secondCont'>
        
        <KontaktDiv>
          <TextKontakt>Mail: terezstarmi@seznam.cz</TextKontakt>
          <TextKontakt>Adresa: Vítězná 106/10, 78401 Litovel</TextKontakt>
          <TextKontakt>IČ: 19324979</TextKontakt>
          <TextKontakt>Tel: 605 803 379</TextKontakt>
          <GDPR to="/gdpr">Zásady Ochrany Osobních údajů</GDPR>
        </KontaktDiv>

        <LinkDiv style={{justifyContent: "center"}}>
          <a href='https://www.instagram.com/barber_ggirl/' target='_blank' rel='noopener noreferrer'><Social src={Instagram} /></a>
          <a href='https://www.facebook.com/teru.gargosowa' target='_blank' rel='noopener noreferrer'><Social src={Facebook} /></a>
        </LinkDiv>
        
        
      </SecondCont>
    </NavCont>
  )
}

export default Footer
