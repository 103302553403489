
import styled from 'styled-components'
import MP4 from '../../Videos/uvod.mp4'
import OGV from '../../Videos/uvodOGV.ogv'
import WEBM from '../../Videos/uvodW.webm'
import G18 from '../../Img/g18.JPG'

import './Video.css'


const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
 
`;



const HeroVid = () => {
  



  return (
    <>
      <Video autoPlay poster={G18} loop muted className="video-js" playsinline>
        <source src={MP4} type="video/mp4" />
        <source src={OGV} type="video/ogg" />
        <source src={WEBM} type="video/webm" />
      </Video>
      </>
  );
};

export default HeroVid;